export default [
  {
    header: 'Charts & Maps',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Table',
        route: 'dashboard-status',
        icon: 'GridIcon',
      },
      {
        title: 'Map',
        route: 'dashboard-map',
        icon: 'MapIcon',
      },
      {
        title: 'Charts',
        route: 'dashboard-chart',
        icon: 'BarChartIcon',
      },
    ],
  },
  {
    header: 'Reports',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Sample',
        route: 'Sample',
        icon: 'PauseIcon',
      },
    ],
  },

  {
    header: 'Locations',
    icon: 'GitPullRequestIcon',
    children: [
      {
        title: 'Sample',
        route: 'Sample',
        icon: 'PauseIcon',
      },
    ],
  },

  {
    header: 'Billing',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'Sample',
        route: 'Sample',
        icon: 'PauseIcon',
      },
    ],
  },

  {
    header: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Sample',
        route: 'Sample',
        icon: 'PauseIcon',
      },
    ],
  },
]
